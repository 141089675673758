import { nebulaClient, nebulaUploadClient } from "../../lib/nebulaClient";
import { TeamService } from "./nebula.service";
import { SessionUuid } from "./uploadSlice";

export interface DerivedFileDTO {
    fileName: string;
    patientId: string;
    laterality: string;
    encounterDate: string;
    mediaUuid: string;
    comment?: string;
    mimeType: string;
    category: string;
    sessionUuid: string;
    status: string;
    isDeleted: boolean;
    statusCode: number;
}

export interface GroupDTO {
    depth: number;
    isActive: boolean;
    label: string;
    name: string;
    parent: string;
    selected: boolean;
    kind: string;
    selectedInherited: boolean;
    uuid: string;
}

export interface TrialDTO {
    current: GroupDTO;
    children: TrialDTO[];
}

export type VisitIdentifierDTO = Record<string, string>;

export interface FileExtensionDTO {
    label: string;
    prettyLabel: string;
}

export interface UploadConfigDTO {
    uuid: string;
    folderConventionRegEx: string[];
    visitIdentifiers: VisitIdentifierDTO;
    validExampleFolders: string[];
    validExtensions: string[];
    uploadSizeLimitMb: number;
}

export interface RawFileDTO {
    data: {
        mediaUuid: string;
        results: string[];
        sessionUuid: string;
    };
    message: string;
    status: number;
}

export interface OrionSessionDTO {
    id: string;
}

export const uploadService: TeamService = {
    getTrials: async () => {
        const response = await nebulaClient.get<TrialDTO[]>("/org/trees/my/org?mode=trial");

        return response;
    },
    getUploadConfig: async () => {
        const response = await nebulaClient.get<UploadConfigDTO>(`/media/upload-config`);

        return response;
    },
    getUploadTrack: async (uploadSessionId: string) => {
        const response = await nebulaClient.get<DerivedFileDTO[]>(`/study/upload/status/${uploadSessionId}`);

        return response;
    },
    getValidFileExtensions: async () => {
        const response = await nebulaClient.get<FileExtensionDTO[]>("/media/valid-extensions");

        return response;
    },
    getSessionStatus: async (sessionUuids: SessionUuid[]) => {
        const sessionsStatus = await Promise.all(
            sessionUuids.map((sessionUuid) => nebulaClient.get(`/study/upload/status/${sessionUuid}`))
        );

        const errorOccurred = sessionsStatus.find((response) => response.error);

        if (errorOccurred) {
            return errorOccurred;
        }

        return { data: sessionsStatus.map((a) => a.data).flat() };
    },
    commitFiles: async (derivedFiles: DerivedFileDTO[]) => {
        const response = await nebulaClient.post<DerivedFileDTO[]>(`/study/upload/status`, derivedFiles);

        return response;
    },
    updateCurrentGroup: async (derivedFiles: DerivedFileDTO[]) => {
        const response = await nebulaClient.post<any>(`/org/assign/my/active`, derivedFiles);

        return response;
    },
    uploadFile: async (rawFile: FormData, signal: AbortSignal, onProgress: (progressEvent: any) => void) => {
        const response: any = await nebulaUploadClient.post<RawFileDTO>("/study/raw/add", rawFile, {
            signal: signal,
            onUploadProgress: onProgress,
        });

        return { data: response?.data?.data, error: response.error };
    },
};
