import { nebulaClient } from "../../lib/nebulaClient";
import { PatientService } from "./nebula.service";
import { IPatient } from "./patientsSlice";

interface PatientDTO {
    isQc: boolean;
    earliestISODate: string;
    dateOfBirth: string;
    gender: string;
    group: string;
    label: string;
    latestISODate: string;
    siteName: string;
    patientId: string;
    patientName: string;
    uuid: string;
    site: string;
    visits: number;
}

export const patientService: PatientService = {
    getPatients: async () => {
        const response = await nebulaClient.get<PatientDTO[]>("/patients?limit=1000");

        return response;
    },
    getPatientById: async (patientId: string) => {
        const response = await nebulaClient.get<PatientDTO>(`/patients/${patientId}`);

        return response;
    },
    editPatient: async (patient: Partial<IPatient>) => {
        const response = await nebulaClient.post<PatientDTO>(`/patients/update`, patient);

        return response;
    },
};
