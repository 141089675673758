import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { serviceSlice } from "../../app/serviceSlice";

export interface IPatient {
    isQc: boolean;
    earliestISODate: string;
    dateOfBirth: string;
    gender: string;
    group: string;
    label: string;
    latestISODate: string;
    siteName: string;
    patientId: string;
    patientName: string;
    uuid: string;
    site: string;
    visits: number;
}

export type Filter = {
    key: string;
    label: string;
    onFilter: (patient: IPatient) => boolean;
};

export const filters: Filter[] = [
    {
        key: "qc",
        label: "QC",
        onFilter: (patient) => !patient.isQc,
    },
];

export interface PatientsState {
    allPatientsList: IPatient[];
    patientsList: IPatient[];
    searchQry?: string;
    sort: { field: keyof IPatient; order: string };
    filter: string[];
    status: "idle" | "success" | "loading" | "failed";
    errorMessage?: string;
    currentPatient?: string;
}

const LOCAL_STORAGE_SORT_KEY = "sortKey";
const LOCAL_STORAGE_FILTER_KEY = "filters";

const getSortOrderFromLocalStorage = () => {
    const sort = localStorage.getItem(LOCAL_STORAGE_SORT_KEY);

    if (!sort) return { field: "patientName", order: "desc" };

    return JSON.parse(sort);
};

const getFilterFromLocalStorage = () => {
    const filter = localStorage.getItem(LOCAL_STORAGE_FILTER_KEY);

    if (!filter) return [];

    return JSON.parse(filter);
};

export const getInitialState: () => PatientsState = () => ({
    allPatientsList: [],
    patientsList: [],
    filter: getFilterFromLocalStorage(),
    sort: getSortOrderFromLocalStorage(),
    status: "loading",
});

export const patientsSlice = createSlice({
    name: "patients",
    initialState: getInitialState,
    reducers: {
        selectPatient: (state, action: PayloadAction<{ currentPatient: IPatient }>) => {
            state.currentPatient = action.payload.currentPatient.uuid;
        },
        deselectPatient: (state) => {
            state.currentPatient = undefined;
        },
        filterPatients: (state, action: PayloadAction<any>) => {
            state.patientsList = state.allPatientsList.filter(
                (patient) =>
                    patient.patientName.toLowerCase().includes(action.payload.val.toLowerCase()) ||
                    patient.patientId.toLowerCase().includes(action.payload.val.toLowerCase())
            );
        },
        sortPatients: (state, action: PayloadAction<any>) => {
            const newSortConfig = { field: action.payload.field, order: action.payload.order };
            localStorage.setItem(LOCAL_STORAGE_SORT_KEY, JSON.stringify(newSortConfig));
            state.sort = newSortConfig;
        },
        setPatientFilter: (state, action: PayloadAction<string[]>) => {
            localStorage.setItem(LOCAL_STORAGE_FILTER_KEY, JSON.stringify(action.payload));
            state.filter = action.payload;
        },
    },
});

export const { selectPatient, sortPatients, deselectPatient, setPatientFilter } = patientsSlice.actions;

export const selectPatients = (state: RootState) => {
    return state.patients;
};

export const selectCurrentFilter = (state: RootState) => state.patients.filter;

export const selectCurrentFiltersConfig = (state: RootState) => {
    return state.patients.filter.map((filterKey) => {
        return filters.find((filter) => filter.key === filterKey) as Filter;
    });
};

export const selectPatientSort = (state: RootState) => {
    return state.patients.sort;
};

export const selectCurrentPatient = (state: RootState) => {
    if (!state.patients.currentPatient) return;

    // @ts-ignore
    const currentPatients = serviceSlice.endpoints.getPatients.select()(state);
    return currentPatients.data.find((patient: IPatient) => patient.uuid === state.patients.currentPatient);
};

export default patientsSlice.reducer;
