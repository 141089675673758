import { serviceSlice } from "../../app/serviceSlice";
import { patientService } from "./nebula.port";
import { IPatient } from "./patientsSlice";

export const extendedApiSlice = serviceSlice.enhanceEndpoints({ addTagTypes: ["PATIENTS"] }).injectEndpoints({
    endpoints: (builder) => ({
        getPatients: builder.query<IPatient[], void>({
            query: () => patientService.getPatients,
            providesTags: ["PATIENTS"],
        }),
        getPatientById: builder.query<IPatient, string>({
            query: (patientId) => () => patientService.getPatientById(patientId),
            transformResponse: (responseData: { data: IPatient }) => {
                return responseData.data;
            },
        }),
        editPatient: builder.mutation<void, Partial<IPatient>>({
            query: (patient) => () => patientService.editPatient(patient),
            async onQueryStarted(patient, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    extendedApiSlice.util.updateQueryData("getPatients", undefined, (draft) => {
                        draft.forEach((aPatient, i) => {
                            if (aPatient.uuid === patient.uuid) {
                                draft[i] = { ...aPatient, ...patient };
                            }
                        });
                    })
                );
                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
        }),
    }),
});

export const { useGetPatientsQuery, useGetPatientByIdQuery, useEditPatientMutation } = extendedApiSlice;
